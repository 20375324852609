import React, { ChangeEvent, useEffect, useState } from "react"
import { User } from "../common/User"
import { MenuContents } from "../common/MenuContents"
import { performJsonAPICall, useLoadData } from "./api"
import _ from "lodash"
import { ReportProblemModal } from "./ReportProblemModal"
import { UserTaggingModal } from "./UserTaggingModal"
import { SiteDesign } from "../common/SiteDesign"

/** Header at top of page when logged in */
export function SiteHeader(props: {
  onLogout: () => void
  onCrossover: (viewUser: User) => void
  loginUser: User
  viewUser: User
  siteDesign: SiteDesign
}) {
  // True if showing report problem modal
    const [showReportProblemModal, setShowReportProblemModal] = useState(false)

  // True if showing user tagging modal
    const [showUserTaggingModal, setShowUserTaggingModal] = useState(false)  

  // Load list of all users
    const [allUsers] = useLoadData<User[]>("/api/get_crossover_users")
   // console.log(JSON.stringify(allUsers,0,4));

  // Get menu contents (interventions, partners and grants)
   const [menuContents] = useLoadData<MenuContents>("/api/get_menu_contents", { userId: props.viewUser.user_id })
    
   
   // const isEnterprise = menuContents.partners[0].isenterprise;

  // console.log(isEnterprise); // Output: "No"
/*
    if (partnerData.partners && partnerData.partners.length > 0) {
        const isEnterprise = partnerData.partners[0].isenterprise;
        console.log('isenterprise value:', isEnterprise);  // Output: "No"
    } else {
        console.log('No partners found.');
    }
    
    */


  const isPartner = props.loginUser.roles.includes(21)
 // const isEnterprise = partners.isenterprise
 //   console.log('isenterprise is ' + isEnterprise)
  //new roles 2 Insigts Admin or 9 Admin get admin link

  const isAdmin = props.loginUser.roles.includes(9) || props.loginUser.roles.includes(2)


  function renderNonPartnerNav() {
    return (
      <ul className="nav nav-pills">
        <DropdownNav key="myportal" label="My Portal">
          <DropdownItem key="my_dashboard" label="My Dashboard" url={`/my_dashboard/${props.viewUser.user_id}`} />
          <DropdownSubmenu key="myinterventions" label="My Interventions">
            {menuContents ? (
              menuContents.interventions.length > 0 ? (
                menuContents.interventions.map((intervention) => (
                  <DropdownItem
                    key={intervention.id}
                    label={intervention.name}
                    url={`/interventions/${intervention.id}`}
                  />
                ))
              ) : (
                <DropdownItem key="none" label="None" disabled />
              )
            ) : null}
          </DropdownSubmenu>
          <DropdownSubmenu key="mypartners" label="My Partners">
            {menuContents ? (
              menuContents.partners.length > 0 ? (
                menuContents.partners.map((partner) => (
                  <DropdownItem key={partner.id} label={partner.name} url={`/partners/${partner.id}`} />
                ))
              ) : (
                <DropdownItem key="none" label="None" disabled />
              )
            ) : null}
          </DropdownSubmenu>
          <DropdownSubmenu key="mygrants" label="My Grants">
            {menuContents ? (
              menuContents.partners.length > 0 ? (
                menuContents.grants.map((grant) => (
                  <DropdownItem key={grant.id} label={grant.name} url={`/grants/${grant.id}`} />
                ))
              ) : (
                <DropdownItem key="none" label="None" disabled />
              )
            ) : null}
          </DropdownSubmenu>
        </DropdownNav>

        <DropdownNav key="lookup" label="Lookup">
          <DropdownItem key="partners" label="Partners" url="/partners" />
          <DropdownItem key="interventions" label="Interventions" url="/interventions" />
          <DropdownItem key="grants" label="Grants" url="/grants" />
          <DropdownItem key="capital_providers" label="Capital Providers" url="/capitalproviders" />
          <DropdownItem key="capital_investments" label="Capital Investments" url="/investments" />
          <DropdownItem key="learnings" label="Learning Portal" url="/learnings" />
          <DropdownItem key="funding_packages" label="Funding Packages" url="/funding_packages" />
          <DropdownItem key="contractors" label="Contractors" url="/contractors" />
        </DropdownNav>

        <DropdownNav key="reporting" label="Reporting">
        <DropdownItem key="global_dash" label="Global" url="/global_dash" />
        <DropdownItem key="country_dash" label="Country" url="/country_dash" />
        <DropdownItem key="grants_dash" label="Grants" url="/grants_dash" />
        <DropdownItem key="impact_dash" label="Fiscal Year Spotlight" url="/impact_dash" />
        <DropdownItem key="asset_originaion" label="Capital Invested" url="/capital_invested" />
        <DropdownItem key="enterprise" label="WSS Businesses" url="/enterprise" />
          <DropdownItem key="impact_update" label="Impact Update" url="/impact_update" />
          <DropdownItem key="pipeline_dash" label="Pipeline" url="/pipeline_dash" />
          <DropdownItem key="Dash" label="External Dashboards" url="/external_dashboards" />
          <DropdownItem key="watercredit_metrics" label="WaterCredit Metrics" url="/wc_metrics" />
          <DropdownItem key="dataexplorer" label="Data Explorer" url="/data_explorer" />
          <DropdownItem key="meta_monitoring" label="Meta Monitoring" url="/meta" />
        </DropdownNav>

        <DropdownNav key="resources" label="Resources">
          <DropdownItem key="guide_dash" label="Guide To Dashboards" url="/guide_dash" />
          <DropdownItem key="resource_library" label="Resource Library" url="/resource_library" />
          <li key="mel">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfWu5zS5sfHYvtOf88fyrGkuss0VA6n5VttPmd0hFhMmVFS0A/viewform?c=0&w=1"
              target="_blank"
              style={{ cursor: "pointer" }}
              className="dropdown-item"
            >
              MEL Request Form
            </a>
          </li>
          <DropdownSubmenu key="mwater" label="mWater">
            <li key="portal">
              <a href="http://portal.mwater.co/#forms" target="_blank" className="dropdown-item">
                Portal
              </a>
            </li>
            <li key="surveyor">
              <a href="https://surveyor.mwater.co/#/" target="_blank" className="dropdown-item">
                Surveyor
              </a>
            </li>
          </DropdownSubmenu>
        </DropdownNav>
      </ul>
    )
  }

    function renderPartnerNav() {
    
    return (
      <ul className="nav nav-pills">
        <DropdownNav key="myinterventions" label="My Interventions">
          {menuContents ? (
            menuContents.interventions.length > 0 && menuContents.partners.length > 0 ? (
              menuContents.interventions.map((intervention) => (
                <DropdownSubmenu key={intervention.id} label={intervention.name}>
                  <DropdownItem key="metrics" label="WaterCredit Metrics" url="/watercredit_metrics" />
                  <DropdownItem
                    key="upload"
                    label="Upload Reports"
                    url={`/upload_report?intervention=${intervention.id}&partner=${menuContents.partners[0].id}`}
                  />
                  <DropdownItem
                    key="review"
                    label="Review Reports"
                    url={`/review_reports?intervention=${intervention.id}&partner=${menuContents.partners[0].id}`}
                      />
                  
                </DropdownSubmenu>
              ))
            ) : (
              <DropdownItem key="none" label="None" disabled />
            )
          ) : null}
        </DropdownNav>
        <DropdownItem key="resource_library" label="Resource Library" url="/resource_library" />
      </ul>
    )
  }

  return (
    <div>
      {showReportProblemModal ? (
        <ReportProblemModal
          onClose={() => setShowReportProblemModal(false)}
          loginUser={props.loginUser}
          viewUser={props.viewUser}
          siteDesign={props.siteDesign}
        />
          ) : null}

       {showUserTaggingModal ? (
              <UserTaggingModal
                  onClose={() => setShowUserTaggingModal(false)}
                  loginUser={props.loginUser}
                  viewUser={props.viewUser}
                  siteDesign={props.siteDesign}
              />
        ) : null}
        <div key="top" style={{ display: "grid", gridTemplateColumns: "auto minmax(20px, 1fr) auto", padding: 32 }}>
          <a href="#/">
            <img src="images/logo-wp.png" />
        </a>
        <div />
        <div>
          Hello {props.viewUser.name}
          <span> | </span>
          {isPartner ? null : (
            <CrossoverControl loginUser={props.loginUser} allUsers={allUsers} onCrossover={props.onCrossover} />
          )}
          {isAdmin ? (
            <span>
              <span> | </span>
              <a style={{ cursor: "pointer" }} href="#/admin">
                Admin
              </a>
            </span>
          ) : null}
          {isPartner ? (
            <span>
              <a style={{ cursor: "pointer" }} href="#/partner_settings">
                Partner Account Settings
              </a>
            </span>
          ) : null}
          <span> | </span>
          <a style={{ cursor: "pointer" }} onClick={props.onLogout}>
            Log out
          </a>
                  <span> | </span>
          <NotificationsControl />
                  <span> | </span>
         <a  style={{ cursor: "pointer" }} onClick={() => setShowReportProblemModal(true)} title="Click here to request a feature or support.">
                   <i className="fa fa-tools fa-fw " />
                  </a>
          <span> | </span>
                  <a style={{ cursor: "pointer" }} onClick={() => setShowUserTaggingModal(true)} title="Click here to tag a user.">
                      <i className="fa fa-tags fa-fw " />
          </a>
        </div>
      </div>
      <div key="nav" style={{ paddingBottom: 20 }}>
        {isPartner ? renderPartnerNav() : renderNonPartnerNav()}
          </div>
      </div>


  )
}





/** Crossover control */
function CrossoverControl(props: {
  loginUser: User
  allUsers: User[] | undefined
  onCrossover: (viewUser: User) => void
}) {
  function handleChange(ev: ChangeEvent<HTMLSelectElement>) {
    if (ev.target.value) {
      props.onCrossover(props.allUsers!.find((u) => u.user_id == parseInt(ev.target.value))!)
    }
  }

  // Put self first
  const users = _.sortBy(props.allUsers || [], (user) => (user.user_id == props.loginUser.user_id ? 0 : 1))

  return (
    <select value="" onChange={handleChange} style={{ outline: 0, border: 0, width: 88, backgroundColor: "white", color: "var(--bs-body-color)" }}>
      <option value="" disabled={true}>
        Crossover
      </option>
      {users.map((user) => (
        <option key={user.user_id} value={user.user_id}>
          {user.name}
        </option>
      ))}
    </select>
  )
}

/** Top-level dropdown nav */
function DropdownNav(props: { label: string; children?: any }) {
  return (
    <li className="dropdown hover-dropdown nav-item">
      <a className="dropdown-toggle nav-link" data-bs-toggle="dropdown" style={{ color: "#6f6f6f", cursor: "default" }}>
        {props.label}
      </a>
      <ul className="dropdown-menu">{props.children}</ul>
    </li>
  )
}

/** Single item in a dropdown */
function DropdownItem(props: { label: string; url?: string; disabled?: boolean }) {
  return (
    <li className={props.disabled ? "disabled" : undefined}>
      <a href={props.url ? "#" + props.url : undefined} className="dropdown-item" style={{ cursor: "pointer" }}>
        {props.label}
      </a>
    </li>
  )
}

/** Submenu in a dropdown nav */
function DropdownSubmenu(props: { label: string; children?: any }) {
  return (
    <li className="dropdown-submenu">
      <a style={{ cursor: "pointer" }} className="dropdown-item">{props.label}</a>
      <ul className="dropdown-menu">{props.children}</ul>
    </li>
  )
}

/** Displays number of notifications, refreshing every minute */
let criticalCount     = 0
let majorCount        = 0
let mediumCount       = 0
let lowCount          = 0
let tagsCount         = 0
function NotificationsControl(props: {}) {
  const [counts, setCounts] = useState<{ cnt: number, severity: string }[]>([])
  criticalCount                        = parseInt(counts.find(c => c.severity == 'Critical')?.cnt)
  majorCount                           = parseInt(counts.find(c => c.severity == 'Major')?.cnt)
  mediumCount                          = parseInt(counts.find(c => c.severity == 'Medium')?.cnt)
  lowCount                             = parseInt(counts.find(c => c.severity == 'Low')?.cnt)
  tagsCount                            = parseInt(counts.find(c => c.severity == 'Tags')?.cnt)
  if (!criticalCount && criticalCount !== 0) { criticalCount = 0 } 
  if (!majorCount && majorCount !== 0) { majorCount = 0 } 
  if (!mediumCount && mediumCount !== 0) { mediumCount = 0 } 
  if (!lowCount && lowCount !== 0) { lowCount = 0 } 
  if (!tagsCount && tagsCount !== 0) { tagsCount = 0 } 

  let   productionSupportCount               = counts.find(c => c.severity == 'Production Support')?.cnt
  let   nonUrgentCount                       = lowCount + mediumCount  + tagsCount 
  let   UrgentCount                          = majorCount + criticalCount
  //Per Heather #594 only have 2 end user bells 1 for Major and 1 fo all others.
    //change severity for tags from Medium to Tags so those counts wont get pulled into Medium severity.
   

  async function refreshCount() {
    setCounts(await performJsonAPICall<[]>("/api/get_notification_count", {}))
  }

  useEffect(() => {
    refreshCount()
    const interval = setInterval(() => refreshCount(), 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <span>
          {UrgentCount != null && UrgentCount > 0 ?
              <a className="text-danger" style={{ cursor: "pointer" }} href="#/notifications" title="Urgent notifications.">
                  <i className="fa fa-bell fa-fw " />
                  <span className="badge bg-danger">{UrgentCount}</span>
        </a> : null
      }

          {nonUrgentCount != null && nonUrgentCount > 0 ?
              <a className="text-primary" style={{ cursor: "pointer" }} href="#/notifications" title="Non urgent notifications.">
                  <i className="fa fa-bell fa-fw " />
                  <span className="badge bg-primary">{nonUrgentCount}</span>
              </a> : null
      }
          
      { productionSupportCount != null && productionSupportCount > 0 ?
                  <a className="text-warning" style={{ cursor: "pointer" }} href="#/notifications" title="Production Support notifications.">
                      <i className="fa fa-bell fa-fw " />
                      <span className="badge bg-warning">{productionSupportCount}</span>
                  </a> : null
       }
    </span>
  );
}
